import React from "react"
// import LinkTransition from '../common/link-transition';

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import ContentWyswyg from '../components/content-wyswyg';

import contentPicture from '../images/content-picture.png';
import contentPicture2 from '../images/content-picture2.png';

// import animationHero from '../animations/2020-09-Ludus-1-collaboration.json';
import animationHero from '../animations/2020-09-Ludus-2-smartMenu.json';
// import animationHero from '../animations/2020-09-Ludus-3-charts.json';

const ContentSample = () => (
  <Layout
    socialDark={true}
    currentPage='content-sample'
  >
    <SEO title="Content Sample" />

    <Section>
      <ContentHero
        lottie={animationHero}
        title="Live collaboration"
        text="<p>When you add a text block in Ludus, you have access to a variety of fonts. You can either access the +800 Google fonts library, use exclusive fonts designed for Ludus or upload your own custom fonts.</p>"
      />
    </Section>

    <Section>
      <ContentWyswyg
        content={[
          {
            type: 'picture',
            src: contentPicture,
            alt: 'Alt image'
          },
          {
            type: 'text',
            html: `
              <h2>Upload your font</h2>

              <p>It is possible to upload your own font in Ludus. If you have no idea where to find some, have a look on DaFont 's website. </p>
              
              <p>In Ludus, you'll find on your right side an "Add" button.  A window will pop-up and go in the "Upload" tab. Drag'n drop any OTF or TTF file in the adequate field or click on the field to search the file in your computer.</p>
            `
          }, {
            type: 'text',
            html: `
              <h2>Choose a font</h2>

              <p>Go to the Google fonts' website to preview any of their 800+ fonts and search it in Ludus. (Font's preview within Ludus will come at a later stage)</p>

              <p>In Ludus, it is the same procedure than "Upload your font". Except that you need to go in "Google fonts" tab.</p>
            `
          },
          {
            type: 'picture',
            src: contentPicture2,
            alt: 'Alt image'
          },
          {
            type: 'text',
            html: `
              <h4>Advice</h4>
              <p class="note">
                Be careful, fonts are authors' property and they are either freeware, shareware, demo versions or public domain. Always check the readme-files in the archives or visit the author's website for details. Contact him/her if you have any doubt.
              </p>
            `
          }
        ]}
      />
    </Section>

    
  </Layout>
)

export default ContentSample;
