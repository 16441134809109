import React from "react"

import { Container, Wyswyg, Picture } from "./styled.js"

const ContentWyswyg = props => {
  return (
    <Container>
      {props.content.map((content, index) => {
        if (content.type === "text") {
          return (
            <Wyswyg
              key={index}
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
          )
        } else if (content.type === "picture") {
          return (
            <Picture key={index}>
              <img src={content.src} alt={content.alt} />
            </Picture>
          )
        } else if (content.type === "quote") {
          return (
            <Wyswyg key={index}>
              <div className="quote">
                <blockquote cite="https://www.huxley.net/bnw/four.html">
                  <p>{content.quote}</p>
                  <div className="quote-author">
                    <span className="quote-author-name">
                      {content.author.name}
                    </span>
                    ,{" "}
                    <span className="quote-author-title">
                      {content.author.title}
                    </span>
                  </div>
                </blockquote>
              </div>
            </Wyswyg>
          )
        } else if (content.type === "tags") {
          return (
            <Wyswyg key={index}>
              <div className="tags">
                <ul>
                  {content.tags.map(tag => (
                    <li>
                      <a href={tag.url}>{tag.label}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </Wyswyg>
          )
        } else if (content.type === "post-navigation") {
          return (
            <Wyswyg key={index}>
              <div className="post-navigation">
                <a
                  href={content.prev.url}
                  className="post-navigation-link prev"
                >
                  <span className="arrow">Previous entry</span>
                  <span className="title">{content.prev.title}</span>
                </a>
                <a
                  href={content.next.url}
                  className="post-navigation-link next"
                >
                  <span className="arrow">Next entry</span>
                  <span className="title">{content.next.title}</span>
                </a>
              </div>
            </Wyswyg>
          )
        } else if (content.type === "people") {
          return (
            <Wyswyg key={index} large={true}>
              <div className="people-cards">
                {content.cards.map((card, i) => (
                  <div className="people-card" key={i}>
                    <div className="avatar">
                      <img src={card.avatar} alt={"Avatar of " + card.name} />
                    </div>
                    <h2 className="name">{card.name}</h2>
                    <h3 className="title">{card.title}</h3>
                    <div className="links">
                      {card.links && card.links.linkedin ? (
                        <a href={card.links.linkedin}>
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.0128 0.9375H2.05898C1.46045 0.9375 0.9375 1.36816 0.9375 1.95967V12.9378C0.9375 13.5325 1.46045 14.0625 2.05898 14.0625H13.0096C13.6113 14.0625 14.0625 13.529 14.0625 12.9378V1.95967C14.066 1.36816 13.6113 0.9375 13.0128 0.9375ZM5.00596 11.8778H3.12568V6.03164H5.00596V11.8778ZM4.13086 5.14277H4.11738C3.51562 5.14277 3.12598 4.69482 3.12598 4.13408C3.12598 3.56309 3.52588 3.12568 4.14111 3.12568C4.75635 3.12568 5.13281 3.55986 5.14629 4.13408C5.146 4.69482 4.75635 5.14277 4.13086 5.14277ZM11.8778 11.8778H9.99756V8.68125C9.99756 7.91543 9.72393 7.39219 9.04365 7.39219C8.52393 7.39219 8.21631 7.74375 8.07949 8.08623C8.02822 8.20928 8.01445 8.37686 8.01445 8.54795V11.8778H6.13418V6.03164H8.01445V6.84521C8.28809 6.45557 8.71553 5.89482 9.71016 5.89482C10.9444 5.89482 11.8781 6.7084 11.8781 8.4624L11.8778 11.8778Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      ) : null}

                      {card.links && card.links.twitter ? (
                        <a href={card.links.twitter}>
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M12.7402 2.46993C12.6535 2.38327 12.5668 2.38327 12.4802 2.4266C12.3502 2.46993 12.1768 2.5566 12.0035 2.64327C12.1768 2.38327 12.3068 2.07993 12.3502 1.81993C12.3935 1.73327 12.3502 1.6466 12.2635 1.60327C12.1768 1.55993 12.0902 1.55993 12.0035 1.60327C11.5702 1.86327 10.9635 2.07993 10.7468 2.12327C10.2702 1.73327 9.6635 1.5166 9.10016 1.5166C8.3635 1.5166 7.67016 1.81993 7.10683 2.33993C6.58683 2.85993 6.2835 3.55327 6.2835 4.33327C4.16016 4.1166 2.9035 2.8166 1.90683 1.60327C1.8635 1.55993 1.77683 1.5166 1.7335 1.5166C1.64683 1.5166 1.6035 1.55993 1.56016 1.60327C0.953497 2.59993 1.0835 3.5966 1.2135 4.07327C1.30016 4.41993 1.43016 4.67993 1.6035 4.93993C1.38683 4.85327 1.2135 4.7666 1.04016 4.59327C0.996831 4.54994 0.866831 4.54993 0.823497 4.54993C0.736831 4.59327 0.693497 4.67993 0.693497 4.7666C0.693497 6.02327 1.56016 6.8466 2.2535 7.27993C2.1235 7.2366 1.9935 7.2366 1.82016 7.14993C1.7335 7.1066 1.64683 7.14994 1.6035 7.19327C1.56016 7.2366 1.51683 7.3666 1.56016 7.40993C1.95016 8.31993 2.81683 9.01327 3.77016 9.1866C2.9035 9.7066 1.51683 10.0099 0.476831 9.92327C0.390164 9.92327 0.260164 9.9666 0.260164 10.0533C0.216831 10.1399 0.260164 10.2699 0.346831 10.3133C1.64683 11.0499 3.0335 11.4399 4.37683 11.4399C6.50016 11.4399 8.6235 10.4866 10.0968 8.7966C11.3968 7.3666 12.0902 5.5466 11.9602 3.94327C12.1768 3.76994 12.3068 3.4666 12.4802 3.16327C12.5668 3.03327 12.6535 2.85994 12.7402 2.72993C12.7835 2.64327 12.7835 2.51327 12.7402 2.46993Z" />
                          </svg>
                        </a>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </Wyswyg>
          )
        } else {
          return null
        }
      })}
    </Container>
  )
}

export default ContentWyswyg
