import styled from 'styled-components';
import variables, { mq, screenSize, fs } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1420px;
  min-height: calc(70vh -  180px);
  padding: 0 20px;
  margin: 0 auto 50px;
`;

export const Wyswyg = styled.div`
  width: 100%;
  max-width: ${props => props.large ? '1140px' : '890px'};
  padding: 0 20px;
  margin: 0 auto 50px;

  h2 {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.3;
    color: ${variables.colors.backgroundDark};
    padding: 20px 0 0;

    ${fs(36)}
  }

  h3 {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.7;
    color: ${variables.colors.backgroundDark};
    padding: 20px 0 0;

    ${fs(24)}
  }
  
  h4 {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.7;
    color: ${variables.colors.backgroundDark};
    padding: 20px 0 0;

    ${fs(18)}
  }

  p {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.7;
    font-weight: 500;
    padding: 10px 0 0;

    ${fs(18)}

    &.note {
      color: ${variables.colors.greyDark};
    }
  }

  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6,
  ul + h2,
  ul + h3,
  ul + h4,
  ul + h5,
  ul + h6,
  ol + h2,
  ol + h3,
  ol + h4,
  ol + h5,
  ol + h6 {
    padding-top: 55px;
  }

  ul, ol {
    padding: 15px 0 0;
  }

  ul li,
  ol li {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.7;
    font-weight: 500;

    ${fs(18)}
  }

  p + ul,
  p + ol {
    margin-top: -25px;
  }

  p, li {
    a {
      color: ${variables.colors.red};
      text-decoration: none;
      font-weight: 600;

      &:hover {
        color: ${variables.colors.blue};
      }
    }
  }

  .tags {
    width: 850px;
    ul {
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      li {
        margin-right: 28px;

        a {
          display: inline-block;
          min-width: 145px;
          padding: 0 10px;
          line-height: 40px;
          background: ${variables.colors.red};
          text-align: center;
          color: ${variables.colors.white};
          text-decoration: none;
          font-weight: 500;
          border-radius: 100px;
          transition: all .3s ease-in;

          ${fs(16)}

          &:hover {
            background: ${variables.colors.blue};
          }
        }

      }
    }
  }

  .quote {
    /* max-width: 560px; */

    ${mq('l', true)} {
      margin-left: 37%;
    }

    blockquote {
      font-weight: 500;
      font-style: italic;
      color: ${variables.colors.greyDark};
      text-align: right;

      ${fs(20)}
    }

    &-author {
      font-weight: 500;
      color: ${variables.colors.backgroundDark};

      ${fs(16)}

      &::before {
        content: '';
        display: inline-block;
        width: 60px;
        height: 1px;
        margin: 5px 20px;
        background: ${variables.colors.backgroundDark};
      }

      &-name {
        font-weight: 700;
      }
    }
  }

  .post-navigation {
    position: relative;
    display: flex;
    border-top: 1px solid ${variables.colors.grey};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      background: ${variables.colors.grey};
    }

    &-link {
      display: block;
      width: 50%;
      padding: 25px 40px;
      text-decoration: none;
      color: ${variables.colors.red};

      span {
        display: block;
        line-height: 1.5;

        &.arrow {
          display: inline-flex;
          align-items: center;
          font-weight: 500;

          ${fs(14)}

          &::before,
          &::after {
            transition: all .3s ease-in;
          }
        }

        &.title {
          font-family: 'Bossa', sans-serif;
          font-weight: 500;
          color: ${variables.colors.black};

          ${fs(16)}
        }
      }

      &:hover {
        .arrow::before {
          transform: translate(-10px, 0);
        }
        .arrow::after {
          transform: translate(10px, 0);
        }

        .title {
          color: ${variables.colors.blue};
        }
      }
    }
    
    .prev {
      padding: 25px 40px 25px 0;
      text-align: right;

      .arrow::before {
        content: '';
        display: inline-block;
        width: 55px;
        height: 8px;
        margin-right: 15px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDU1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY0NjQ0MiAzLjY0NjQ0QzAuNDUxMTggMy44NDE3IDAuNDUxMTc5IDQuMTU4MjkgMC42NDY0NDIgNC4zNTM1NUwzLjgyODQyIDcuNTM1NTNDNC4wMjM2OSA3LjczMDc5IDQuMzQwMjcgNy43MzA3OSA0LjUzNTUzIDcuNTM1NTNDNC43MzA3OSA3LjM0MDI3IDQuNzMwNzkgNy4wMjM2OCA0LjUzNTUzIDYuODI4NDJMMS43MDcxIDRMNC41MzU1MyAxLjE3MTU3QzQuNzMwNzkgMC45NzYzMDYgNC43MzA3OSAwLjY1OTcyNCA0LjUzNTUzIDAuNDY0NDYyQzQuMzQwMjcgMC4yNjkxOTkgNC4wMjM2OSAwLjI2OTE5OSAzLjgyODQyIDAuNDY0NDYyTDAuNjQ2NDQyIDMuNjQ2NDRaTTU1IDMuNUwwLjk5OTk5NiAzLjVMMC45OTk5OTYgNC41TDU1IDQuNUw1NSAzLjVaIiBmaWxsPSIjRkY2NTYyIi8+Cjwvc3ZnPgo=")
      }
    }

    .next {
      padding: 25px 0 25px 40px;
      text-align: left;

      .arrow::after {
        content: '';
        display: inline-block;
        width: 55px;
        height: 8px;
        margin-left: 15px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDU1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01NC4zNTM2IDQuMzUzNTVDNTQuNTQ4OCA0LjE1ODI5IDU0LjU0ODggMy44NDE3MSA1NC4zNTM2IDMuNjQ2NDVMNTEuMTcxNiAwLjQ2NDQ2NkM1MC45NzYzIDAuMjY5MjA0IDUwLjY1OTcgMC4yNjkyMDQgNTAuNDY0NSAwLjQ2NDQ2NkM1MC4yNjkyIDAuNjU5NzI4IDUwLjI2OTIgMC45NzYzMTEgNTAuNDY0NSAxLjE3MTU3TDUzLjI5MjkgNEw1MC40NjQ1IDYuODI4NDNDNTAuMjY5MiA3LjAyMzY5IDUwLjI2OTIgNy4zNDAyNyA1MC40NjQ1IDcuNTM1NTNDNTAuNjU5NyA3LjczMDggNTAuOTc2MyA3LjczMDggNTEuMTcxNiA3LjUzNTUzTDU0LjM1MzYgNC4zNTM1NVpNMCA0LjVMNTQgNC41VjMuNUwwIDMuNUwwIDQuNVoiIGZpbGw9IiNGRjY1NjIiLz4KPC9zdmc+Cg==")
      }
    }

  }

  .people-cards {
    display: flex;
    margin: 0 -11px;

    ${mq('s')} {
      flex-direction: column;
    }
  }

  .people-card {
    display: flex;
    flex-direction: column;
    width: calc(100% - 22px);
    margin: 0 11px 20px;
    padding: 20px 20px 0;
    text-align: center;
    background: ${variables.colors.white};
    border-radius: 8px;
    overflow: hidden;
    box-shadow: -1px 9px 20px rgba(116, 116, 188, 0.25);
    
    .avatar {
      display: block;
      width: 81px;
      height: 81px;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
      background: ${variables.colors.greyLighter};
      ${screenSize('margin-bottom', 10)}
      
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      font-family: 'Bossa', sans-serif;
      font-weight: 500;
      color: ${variables.colors.backgroundDark};
      line-height: 1.3;
      padding: 0;
      ${fs(36)}
      ${screenSize('margin-bottom', 10)}
    }

    .title {
      flex: 1;
      font-family: 'Bossa', sans-serif;
      font-weight: 700;
      color: ${variables.colors.blue};
      line-height: 1.5;
      padding: 0;
      ${fs(18)}
      ${screenSize('margin-bottom', 27)}
    }

    .links {
      background: ${variables.colors.blue};
      margin: 0 -20px;
      padding: 34px 40px;

      ${screenSize('padding-top', 34)}
      ${screenSize('padding-bottom', 34)}

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: ${variables.colors.white};

        ${screenSize('width', 40)}
        ${screenSize('height', 40)}

        + a {
          ${screenSize('margin-left', 20)}
        }

        svg path {
          fill: ${variables.colors.blue};
        }

        &:hover {
          svg path {
            fill: ${variables.colors.red};
          }
        }
      }
    }
  }
`;

export const Picture = styled.div`
  max-width: 1180px;
  padding: 0 20px;
  margin: 0 auto 65px;

  img {
    max-width: 100%;
  }
`;